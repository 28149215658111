import React from "react";
import cx from "classnames";
import { useMutation } from "react-query";
import type { StepWizardChildProps } from "react-step-wizard";

import { Membership } from "../../../shared/api/interfaces";
import { CheckmarkIcon, CopyIcon, ForkIcon } from "../icons";
import Footer from "../components/Footer";
import { ensure } from "../../shared/ensure";
import { cloneRepository } from "../../../shared/api/requests";

interface Props extends Partial<StepWizardChildProps> {
  membership: Membership;
  projectId: string;
}

const CloneRepository = ({
  membership,
  projectId,
  ...stepWizardChildProps
}: Props): JSX.Element => {
  // Workaround for react-step-wizard having poor type definitions
  const totalSteps = ensure(stepWizardChildProps.totalSteps);
  const currentStep = ensure(stepWizardChildProps.currentStep);
  const nextStep = ensure(stepWizardChildProps.nextStep);

  const { mutate, isLoading, isSuccess } = useMutation((membershipId: string) =>
    cloneRepository(membershipId)
  );
  const wasCloned = !!membership?.githubRepositoryId || isSuccess;

  return (
    <div className="step-container step-width">
      <div className="step-container__icon">
        <ForkIcon />
      </div>

      <div className="step-container__body">
        <h2>Copy project template</h2>

        <p>
          Using the GitHub connection we will copy the template repo to your
          profile.
        </p>
        <div className="step-container__body-actions">
          <button
            className={cx("action-button", { completed: wasCloned })}
            disabled={isLoading || wasCloned}
            onClick={() => mutate(membership.id)}
          >
            {wasCloned ? (
              <>
                <CheckmarkIcon />
                DONE!
              </>
            ) : isLoading ? (
              <>
                <CopyIcon />
                Loading
              </>
            ) : (
              <>
                <CopyIcon />
                Copy
              </>
            )}
          </button>
        </div>
      </div>

      <Footer
        totalSteps={totalSteps}
        currentStep={currentStep}
        nextStep={nextStep}
        nextStepButtonDisabled={!wasCloned}
      />
    </div>
  );
};

export default CloneRepository;
