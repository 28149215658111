import React from "react";
import MDEditor from "@uiw/react-md-editor";
import styled from "styled-components";

type MarkdownParserProps = {
  markdown: string;
};

const Parser = styled(MDEditor.Markdown)`
  // copied from https://github.com/sindresorhus/github-markdown-css/blob/main/github-markdown.css
  .markdown-wrapper {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    line-height: 1.5;
    font-size: 0.75rem;
    line-height: 1.5;
    word-wrap: break-word;
    font-weight: 400;
    > * {
      color: #6b778c;
    }
    .anchor {
      float: left;
      line-height: 1;
      margin-left: -20px;
      padding-right: 4px;
    }
    .anchor:focus {
      outline: none;
    }
    details {
      display: block;
    }
    summary {
      display: list-item;
    }
    a {
      background-color: initial;
    }
    a:active,
    a:hover {
      outline-width: 0;
    }
    strong {
      font-weight: inherit;
      font-weight: bolder;
    }
    h1 {
      font-size: 2em;
      margin: 0.67em 0;
    }
    img {
      border-style: none;
    }
    code,
    kbd,
    pre {
      font-family: monospace, monospace;
      font-size: 1em;
    }
    hr {
      box-sizing: initial;
      height: 0;
      overflow: visible;
    }
    input {
      font: inherit;
      margin: 0;
    }
    input {
      overflow: visible;
    }
    [type="checkbox"] {
      box-sizing: border-box;
      padding: 0;
    }
    * {
      box-sizing: border-box;
    }
    input {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }
    a {
      color: #0366d6;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
    strong {
      font-weight: 600;
    }
    hr {
      height: 0;
      margin: 15px 0;
      overflow: hidden;
      background: transparent;
      border: 0;
      border-bottom: 1px solid #dfe2e5;
    }
    hr:after,
    hr:before {
      display: table;
      content: "";
    }
    hr:after {
      clear: both;
    }
    table {
      border-spacing: 0;
      border-collapse: collapse;
    }
    td,
    th {
      padding: 0;
    }
    details summary {
      cursor: pointer;
    }
    kbd {
      display: inline-block;
      padding: 3px 5px;
      font: 11px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
      line-height: 10px;
      color: #a0a3a7;
      vertical-align: middle;
      background-color: #fafbfc;
      border: 1px solid #d1d5da;
      border-radius: 3px;
      box-shadow: inset 0 -1px 0 #d1d5da;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      margin-bottom: 0;
    }
    h1 {
      font-size: 32px;
    }
    h1,
    h2 {
      font-weight: 600;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 20px;
    }
    h3,
    h4 {
      font-weight: 600;
    }
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 14px;
    }
    h5,
    h6 {
      font-weight: 600;
    }
    h6 {
      font-size: 0.75rem;
    }
    p {
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 400;
    }
    blockquote {
      margin: 0;
    }
    ol,
    ul {
      font-weight: 400;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
    ol ol,
    ul ol {
      list-style-type: lower-roman;
    }
    ol ol ol,
    ol ul ol,
    ul ol ol,
    ul ul ol {
      list-style-type: lower-alpha;
    }
    dd {
      margin-left: 0;
    }
    code,
    pre {
      font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
      font-size: 0.75rem;
    }
    pre {
      margin-top: 0;
      margin-bottom: 0;
    }
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
      appearance: none;
    }
    :checked + .radio-label {
      position: relative;
      z-index: 1;
      border-color: #0366d6;
    }
    .border {
      border: 1px solid #e1e4e8 !important;
    }
    .border-0 {
      border: 0 !important;
    }
    .border-bottom {
      border-bottom: 1px solid #e1e4e8 !important;
    }
    .rounded-1 {
      border-radius: 3px !important;
    }
    .bg-white {
      background-color: #fff !important;
    }
    .bg-gray-light {
      background-color: #fafbfc !important;
    }
    .text-gray-light {
      color: #6a737d !important;
    }
    .mb-0 {
      margin-bottom: 0 !important;
    }
    .my-2 {
      margin-top: 8px !important;
      margin-bottom: 8px !important;
    }
    .pl-0 {
      padding-left: 0 !important;
    }
    .py-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .pl-1 {
      padding-left: 4px !important;
    }
    .pl-2 {
      padding-left: 8px !important;
    }
    .py-2 {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
    .pl-3,
    .px-3 {
      padding-left: 16px !important;
    }
    .px-3 {
      padding-right: 16px !important;
    }
    .pl-4 {
      padding-left: 24px !important;
    }
    .pl-5 {
      padding-left: 32px !important;
    }
    .pl-6 {
      padding-left: 40px !important;
    }
    .f6 {
      font-size: 0.75rem !important;
    }
    .lh-condensed {
      line-height: 1.25 !important;
    }
    .text-bold {
      font-weight: 600 !important;
    }
    .pl-c {
      color: #6a737d;
    }
    .pl-c1,
    .pl-s .pl-v {
      color: #005cc5;
    }
    .pl-e,
    .pl-en {
      color: #6f42c1;
    }
    .pl-s .pl-s1,
    .pl-smi {
      color: #24292e;
    }
    .pl-ent {
      color: #22863a;
    }
    .pl-k {
      color: #d73a49;
    }
    .pl-pds,
    .pl-s,
    .pl-s .pl-pse .pl-s1,
    .pl-sr,
    .pl-sr .pl-cce,
    .pl-sr .pl-sra,
    .pl-sr .pl-sre {
      color: #032f62;
    }
    .pl-smw,
    .pl-v {
      color: #e36209;
    }
    .pl-bu {
      color: #b31d28;
    }
    .pl-ii {
      color: #fafbfc;
      background-color: #b31d28;
    }
    .pl-c2 {
      color: #fafbfc;
      background-color: #d73a49;
    }
    .pl-c2:before {
      content: "^M";
    }
    .pl-sr .pl-cce {
      font-weight: 700;
      color: #22863a;
    }
    .pl-ml {
      color: #735c0f;
    }
    .pl-mh,
    .pl-mh .pl-en,
    .pl-ms {
      font-weight: 700;
      color: #005cc5;
    }
    .pl-mi {
      font-style: italic;
      color: #24292e;
    }
    .pl-mb {
      font-weight: 700;
      color: #24292e;
    }
    .pl-md {
      color: #b31d28;
      background-color: #ffeef0;
    }
    .pl-mi1 {
      color: #22863a;
      background-color: #f0fff4;
    }
    .pl-mc {
      color: #e36209;
      background-color: #ffebda;
    }
    .pl-mi2 {
      color: #f6f8fa;
      background-color: #005cc5;
    }
    .pl-mdr {
      font-weight: 700;
      color: #6f42c1;
    }
    .pl-ba {
      color: #586069;
    }
    .pl-sg {
      color: #959da5;
    }
    .pl-corl {
      text-decoration: underline;
      color: #032f62;
    }
    .mb-0 {
      margin-bottom: 0 !important;
    }
    .my-2 {
      margin-bottom: 8px !important;
    }
    .my-2 {
      margin-top: 8px !important;
    }
    .pl-0 {
      padding-left: 0 !important;
    }
    .py-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .pl-1 {
      padding-left: 4px !important;
    }
    .pl-2 {
      padding-left: 8px !important;
    }
    .py-2 {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
    .pl-3 {
      padding-left: 16px !important;
    }
    .pl-4 {
      padding-left: 24px !important;
    }
    .pl-5 {
      padding-left: 32px !important;
    }
    .pl-6 {
      padding-left: 40px !important;
    }
    .pl-7 {
      padding-left: 48px !important;
    }
    .pl-8 {
      padding-left: 64px !important;
    }
    .pl-9 {
      padding-left: 80px !important;
    }
    .pl-10 {
      padding-left: 96px !important;
    }
    .pl-11 {
      padding-left: 112px !important;
    }
    .pl-12 {
      padding-left: 128px !important;
    }
    hr {
      border-bottom-color: #eee;
    }
    kbd {
      display: inline-block;
      padding: 3px 5px;
      font: 11px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
      line-height: 10px;
      color: #444d56;
      vertical-align: middle;
      background-color: #fafbfc;
      border: 1px solid #d1d5da;
      border-radius: 3px;
      box-shadow: inset 0 -1px 0 #d1d5da;
    }
    .markdown-body:after,
    .markdown-body:before {
      display: table;
      content: "";
    }
    .markdown-body:after {
      clear: both;
    }
    .markdown-body > :first-child {
      margin-top: 0 !important;
    }
    .markdown-body > :last-child {
      margin-bottom: 0 !important;
    }
    a:not([href]) {
      color: inherit;
      text-decoration: none;
    }
    blockquote,
    details,
    dl,
    ol,
    p,
    pre,
    table,
    ul {
      margin-top: 0;
      margin-bottom: 16px;
    }
    hr {
      height: 0.25em;
      padding: 0;
      margin: 24px 0;
      background-color: #e1e4e8;
      border: 0;
    }
    blockquote {
      padding: 0 1em;
      color: #6a737d;
      border-left: 0.25em solid #dfe2e5;
    }
    blockquote > :first-child {
      margin-top: 0;
    }
    blockquote > :last-child {
      margin-bottom: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 24px;
      margin-bottom: 16px;
      font-weight: 600;
      line-height: 1.25;
    }
    h1 {
      font-size: 2em;
    }
    h1,
    h2 {
      padding-bottom: 0.3em;
      border-bottom: 1px solid #eaecef;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.25em;
    }
    h4 {
      font-size: 1em;
    }
    h5 {
      font-size: 0.875em;
    }
    h6 {
      font-size: 0.85em;
      color: #6a737d;
    }
    ol,
    ul {
      padding-left: 2em;
    }
    ol ol,
    ol ul,
    ul ol,
    ul ul {
      margin-top: 0;
      margin-bottom: 0;
    }
    li {
      list-style: disc;
      word-wrap: break-all;
    }
    li > p {
      margin-top: 16px;
    }
    li + li {
      margin-top: 0.25em;
    }
    dl {
      padding: 0;
    }
    dl dt {
      padding: 0;
      margin-top: 16px;
      font-size: 1em;
      font-style: italic;
      font-weight: 600;
    }
    dl dd {
      padding: 0 16px;
      margin-bottom: 16px;
    }
    table {
      display: block;
      width: 100%;
      overflow: auto;
    }
    table th {
      font-weight: 600;
    }
    table td,
    table th {
      padding: 6px 13px;
      border: 1px solid #dfe2e5;
    }
    table tr {
      background-color: #fff;
      border-top: 1px solid #c6cbd1;
    }
    table tr:nth-child(2n) {
      background-color: #f6f8fa;
    }
    img {
      max-width: 100%;
      box-sizing: initial;
      background-color: #fff;
    }
    img[align="right"] {
      padding-left: 20px;
    }
    img[align="left"] {
      padding-right: 20px;
    }
    code {
      padding: 0.2em 0.4em;
      margin: 0;
      font-size: 85%;
      background-color: rgba(27, 31, 35, 0.05);
      border-radius: 3px;
    }
    pre {
      word-wrap: normal;
    }
    pre > code {
      padding: 0;
      margin: 0;
      font-size: 100%;
      word-break: normal;
      white-space: pre;
      background: transparent;
      border: 0;
    }
    .highlight {
      margin-bottom: 16px;
    }
    .highlight pre {
      margin-bottom: 0;
      word-break: normal;
    }
    .highlight pre,
    pre {
      padding: 16px;
      overflow: auto;
      font-size: 85%;
      line-height: 1.45;
      background-color: #f6f8fa;
      border-radius: 3px;
    }
    pre code {
      display: inline;
      max-width: auto;
      padding: 0;
      margin: 0;
      overflow: visible;
      line-height: inherit;
      word-wrap: normal;
      background-color: initial;
      border: 0;
    }
    .commit-tease-sha {
      display: inline-block;
      font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
      font-size: 90%;
      color: #444d56;
    }
    .full-commit .btn-outline:not(:disabled):hover {
      color: #005cc5;
      border-color: #005cc5;
    }
    .blob-wrapper {
      overflow-x: auto;
      overflow-y: hidden;
    }
    .blob-wrapper-embedded {
      max-height: 240px;
      overflow-y: auto;
    }
    .blob-num {
      width: 1%;
      min-width: 50px;
      padding-right: 10px;
      padding-left: 10px;
      font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
      font-size: 0.75rem;
      line-height: 20px;
      color: rgba(27, 31, 35, 0.3);
      text-align: right;
      white-space: nowrap;
      vertical-align: top;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .blob-num:hover {
      color: rgba(27, 31, 35, 0.6);
    }
    .blob-num:before {
      content: attr(data-line-number);
    }
    .blob-code {
      position: relative;
      padding-right: 10px;
      padding-left: 10px;
      line-height: 20px;
      vertical-align: top;
    }
    .blob-code-inner {
      overflow: visible;
      font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
      font-size: 0.75rem;
      color: #24292e;
      word-wrap: normal;
      white-space: pre;
    }
    .pl-token.active,
    .pl-token:hover {
      cursor: pointer;
      background: #ffea7f;
    }
    .tab-size[data-tab-size="1"] {
      -moz-tab-size: 1;
      tab-size: 1;
    }
    .tab-size[data-tab-size="2"] {
      -moz-tab-size: 2;
      tab-size: 2;
    }
    .tab-size[data-tab-size="3"] {
      -moz-tab-size: 3;
      tab-size: 3;
    }
    .tab-size[data-tab-size="4"] {
      -moz-tab-size: 4;
      tab-size: 4;
    }
    .tab-size[data-tab-size="5"] {
      -moz-tab-size: 5;
      tab-size: 5;
    }
    .tab-size[data-tab-size="6"] {
      -moz-tab-size: 6;
      tab-size: 6;
    }
    .tab-size[data-tab-size="7"] {
      -moz-tab-size: 7;
      tab-size: 7;
    }
    .tab-size[data-tab-size="8"] {
      -moz-tab-size: 8;
      tab-size: 8;
    }
    .tab-size[data-tab-size="9"] {
      -moz-tab-size: 9;
      tab-size: 9;
    }
    .tab-size[data-tab-size="10"] {
      -moz-tab-size: 10;
      tab-size: 10;
    }
    .tab-size[data-tab-size="11"] {
      -moz-tab-size: 11;
      tab-size: 11;
    }
    .tab-size[data-tab-size="12"] {
      -moz-tab-size: 12;
      tab-size: 12;
    }
    .task-list-item {
      list-style-type: none;
    }
    .task-list-item + .task-list-item {
      margin-top: 3px;
    }
    .task-list-item input {
      margin: 0 0.2em 0.25em -1.6em;
      vertical-align: middle;
    }
  }
`;

export const MarkdownParser: React.FC<MarkdownParserProps> = ({ markdown }) => {
  return <Parser className="markdown-wrapper" source={markdown} />;
};
