import React from "react";
import { QueryClientProvider } from "react-query";
import { queryClient } from "shared/api/queryClient";
import { Quiz as QuizT } from "shared/api/interfaces";
import { Quiz } from "./Quiz";
import "./style.scss";

const QuizApp = ({ quiz }: { quiz: QuizT }): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <Quiz quiz={quiz} />
    </QueryClientProvider>
  );
};

export default QuizApp;
