import axios, { AxiosResponse } from "axios";
import type { QuizAttempt, QuizSummary } from "./interfaces";

const api = axios.create();

export async function startQuiz(quizId: string): Promise<QuizAttempt> {
  const response = await api.post(`/api/v1/dev/quizzes/${quizId}/attempts`);

  return response.data;
}

export async function answerQuizQuestion(
  quizId: string,
  answersIds: string[]
): Promise<QuizAttempt> {
  const formData = new FormData();
  answersIds.map((id) => formData.append("answer_ids[]", id));
  const response = await api.post(
    `/api/v1/dev/quizzes/${quizId}/attempts/answer`,
    formData
  );

  return response.data;
}

export async function getQuizSummary(
  quizId: string,
  attemptId: string
): Promise<QuizSummary> {
  const response = await axios.get<QuizSummary>(
    `/api/v1/dev/quizzes/${quizId}/attempts/${attemptId}/summary`
  );

  return response.data;
}

export async function cloneRepository(
  membershipId: string
): Promise<AxiosResponse> {
  const response = await axios.post(
    `/api/v1/students/membership/${membershipId}/onboarding/clone_repository.json`
  );

  return response.data;
}

export async function completeOnboarding(
  membershipId: string
): Promise<AxiosResponse> {
  const response = await axios.post(
    `/api/v1/students/membership/${membershipId}/onboarding/complete.json`
  );

  return response.data;
}
