import React from "react";
import { CircularProgress, createStyles, makeStyles } from "@material-ui/core";
import { colors } from "../colors";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bottom: {
      color: colors.disabled,
    },
    top: {
      color: colors.disabled,
      animationDuration: "900ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      color: colors.fontActive,
      strokeLinecap: "round",
    },
  })
);

interface LoaderProps {
  size?: number;
  thickness?: number;
  hide?: boolean;
  style?: React.CSSProperties;
}
export const Loader: React.FC<LoaderProps> = ({
  size = 25,
  thickness = 4,
  hide = false,
  style = {},
}) => {
  const classes = useStyles();
  if (hide) {
    return <></>;
  }
  return (
    <div className="d-flex justify-content-center mt-4 mb-4 align-middle">
      <div className={classes.root} style={style}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={size}
          thickness={thickness}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={size}
          thickness={thickness}
        />
      </div>
    </div>
  );
};
