import React from "react";
import type { StepWizardChildProps } from "react-step-wizard";

import { Membership } from "../../../shared/api/interfaces";
import { CheckmarkIcon, GithubLogo, LinkIcon, SigilLogo } from "../icons";
import Footer from "../components/Footer";
import { ensure } from "../../shared/ensure";

interface Props extends Partial<StepWizardChildProps> {
  membership: Membership;
  authToken: string;
}

const ConnectGithubAccount = ({
  membership,
  authToken,
  ...stepWizardChildProps
}: Props): JSX.Element => {
  // Workaround for react-step-wizard having poor type definitions
  const totalSteps = ensure(stepWizardChildProps.totalSteps);
  const currentStep = ensure(stepWizardChildProps.currentStep);
  const nextStep = ensure(stepWizardChildProps.nextStep);

  const nextStepDisabled = !membership.isConnectedWithGithub;

  return (
    <div className="step-container step-width">
      <div className="step-container__icon">
        <GithubLogo />
        <LinkIcon fill="#000" />
        <SigilLogo />
      </div>

      <div className="step-container__body">
        <h2>Connect Github Account</h2>

        <p>
          We need the connection to be able to prepare for you initial
          repository.
        </p>

        <div className="step-container__body-actions">
          {nextStepDisabled ? (
            <form
              className="button_to"
              method="post"
              action="/auth/github"
              data-turbo="false"
            >
              <button
                data-turbo="false"
                type="submit"
                className="action-button"
              >
                <LinkIcon fill="#fff" />
                <span>Connect GitHub account</span>
              </button>
              <input
                type="hidden"
                name="authenticity_token"
                value={authToken}
              />
            </form>
          ) : (
            <span className="action-button completed">
              <CheckmarkIcon />
              DONE!
            </span>
          )}
        </div>
      </div>

      <Footer
        totalSteps={totalSteps}
        currentStep={currentStep}
        nextStep={nextStep}
        nextStepButtonDisabled={nextStepDisabled}
      />
    </div>
  );
};

export default ConnectGithubAccount;
