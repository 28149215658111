import React from "react";

import { ArrowRight } from "../../icons";

interface Props {
  nextStepButtonDisabled: boolean;
  totalSteps: number;
  currentStep: number;
  nextStepEnabled?: boolean;
  nextStep: () => void;
}

const Footer = ({
  totalSteps,
  currentStep,
  nextStepButtonDisabled,
  nextStepEnabled = true,
  nextStep,
}: Props): JSX.Element => {
  const isActive = (stepNumber: number) => stepNumber === currentStep;
  const isLastStep = currentStep === totalSteps;

  const renderSteps = () => {
    const steps = [];
    for (let i = 1; i <= totalSteps; i++) {
      steps.push(<li key={i} className={isActive(i) ? "active" : ""}></li>);
    }

    return steps;
  };

  return (
    <div className="step-container__footer">
      <ul className="steps-indicator">{renderSteps()}</ul>

      {nextStepEnabled && (
        <button
          className="clean-button"
          onClick={nextStep}
          disabled={nextStepButtonDisabled}
        >
          {isLastStep ? "Let's kickoff!" : "Next"}
          <ArrowRight className={nextStepButtonDisabled ? "" : "active"} />
        </button>
      )}
    </div>
  );
};

export default Footer;
