import React from "react";
import { createStyles, Button, withStyles } from "@material-ui/core";
import { colors } from "../../shared/colors";

export const ButtonMain = withStyles(() =>
  createStyles({
    root: {
      color: colors.fontLightDarker,
      fill: colors.fontLightDarker,
      fontFamily: '"Poppins", sans-serif',
      fontSize: "0.75rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: colors.fontActive,
      borderRadius: "12px",
      fontWeight: 600,
      padding: "19px 27px",
      lineHeight: 2,
      whiteSpace: "nowrap",
      height: "48px",
      width: "100%",
      textTransform: "uppercase",
      transition: "250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        background: colors.fontActiveDarker,
        color: colors.fontLightDarker,
        fill: colors.fontLightDarker,
      },
    },
    label: {
      marginTop: "2px",
    },
    disabled: {
      color: `${colors.disabledButton} !important`,
      fill: colors.disabledButton,
      background: colors.backgroundBase,
    },
  })
)(Button) as typeof Button; // https://github.com/mui-org/material-ui/issues/23780
