import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import styled from "styled-components";
import { colors } from "../shared/colors";

const Timer = styled.span`
  color: ${colors.fontSecondary};
  font-size: 0.75rem;
`;

const LinearProgressWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  > ${Timer} {
    width: 30%;
  }
`;

const LinearProgressStyled = withStyles({
  root: {
    height: 2,
    width: "70%",
  },
  colorPrimary: {
    backgroundColor: colors.shadowColor,
  },
  bar: {
    backgroundColor: colors.fontActive,
  },
})(LinearProgress);

interface LinearDeterminateProps {
  timeLimit: number;
  id: string;
}

export const LinearDeterminate: React.FC<LinearDeterminateProps> = ({
  timeLimit,
  id,
}) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === timeLimit) {
          clearInterval(timer);
        } else if (oldProgress < timeLimit) {
          return oldProgress + 1;
        }
        return oldProgress;
      });
    }, 1000);
  }, []);

  React.useEffect(() => {
    setProgress(0);
  }, [id]);

  const timeLeft = timeLimit - progress;
  const countingDown = timeLeft > 0;
  const calculateProgress = (progress * 100) / timeLimit;

  return countingDown ? (
    <LinearProgressWrapper>
      <Timer>Time left: {timeLeft}</Timer>
      <LinearProgressStyled variant="determinate" value={calculateProgress} />
    </LinearProgressWrapper>
  ) : (
    <Timer>Time is up!</Timer>
  );
};
