import React, { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Checkbox, withStyles } from "@material-ui/core";

import { colors } from "../shared/colors";
import { MarkdownParser } from "../shared/components/MarkdownParser";
import { ButtonMain } from "./components/ButtonMain";
import { ModalComponent } from "./components/ModalComponent";
import { QuizStatus } from "../../shared/api/enums";
import { answerQuizQuestion, startQuiz } from "../../shared/api/requests";
import { QuizAttempt, Quiz as QuizT } from "../../shared/api/interfaces";

import { ProgressBar } from "./ProgressBar";
import { LinearDeterminate } from "./LinearProgress";
import { QuizSummary } from "./components/QuizSummary";
import "./style.scss";

const StyledCheckbox = withStyles({
  root: {
    padding: 0,
    marginRight: "1rem",
    "&$checked": {
      color: colors.fontActive,
    },
  },
  checked: {},
})(Checkbox);

const ModalHeader = styled.div`
  background-color: ${colors.backgroundBase};
  color: ${colors.fontSecondary};
  font-size: 12px;
  padding: 1rem 3rem;
  border: 4px solid white;
  display: flex;
`;

const ModalWrapper = styled.div`
  overflow: hidden;
  background-color: ${colors.backgroundComponent};
  border-radius: 12px;
  box-shadow: 0px 1px 0 0 ${colors.shadowColor};
  width: 80vw;

  ul {
    margin-bottom: 2rem;
  }

  li {
    margin-bottom: 1rem;

    label {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      color: ${colors.fontSecondary};
    }
  }

  button {
    width: auto;
    margin: 3rem 0 3.5rem;
  }
`;

const ModalContent = styled.div`
  padding: 1.5rem 3rem;
`;

const QuestionContent = styled.h4`
  font-size: 1.3rem;
  color: ${colors.fontBase};
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 1.5rem;
`;

type SetProgressCallback = (progress: QuizAttempt) => void;

const StartQuizButton: React.FC<{
  setProgress: SetProgressCallback;
  quiz: QuizT;
}> = ({ setProgress, quiz }) => {
  const startQuizMutation = useMutation((id: string) => startQuiz(id), {
    onSuccess: (attempt) => {
      setProgress(attempt);
    },
  });

  return (
    <ButtonMain onClick={() => startQuizMutation.mutate(quiz.id)}>
      Start the quiz
    </ButtonMain>
  );
};

const QuizAction: React.FC<{ setProgress: SetProgressCallback; quiz: QuizT }> =
  ({ setProgress, quiz }) => {
    // const quiz = useActiveQuiz();

    if (quiz.state === QuizStatus.Unlocked) {
      return <StartQuizButton setProgress={setProgress} quiz={quiz} />;
    } else if (quiz.state === QuizStatus.Passed) {
      return <ButtonMain disabled>You already passed this quiz</ButtonMain>;
    }
    return null;
  };

export const Quiz = ({ quiz }: { quiz: QuizT }): JSX.Element => {
  // const quiz = useActiveQuiz();
  const [progress, setProgress] = useState<QuizAttempt>();
  const [selectedAnswersIds, setSelectedAnswersIds] = useState<string[]>([]);

  const answerQuestionMutation = useMutation(
    () => answerQuizQuestion(quiz.id, selectedAnswersIds),
    {
      onSuccess: (attempt) => {
        setProgress(attempt);
        setSelectedAnswersIds([]);
      },
    }
  );

  const handleOnClick = (id: string) => {
    if (selectedAnswersIds.includes(id)) {
      setSelectedAnswersIds(
        selectedAnswersIds.filter((answerId) => answerId != id)
      );
    } else {
      setSelectedAnswersIds([...selectedAnswersIds, id]);
    }
  };

  return (
    <div className="quiz-wrapper">
      <div className="actions">
        <QuizAction setProgress={setProgress} quiz={quiz} />
      </div>

      {!!progress && (
        <ModalComponent
          open={true}
          setOpen={() => ({})}
          setClose={() => ({})}
          withoutContentWrapper
        >
          {progress.isFinished ? (
            <QuizSummary
              progress={progress}
              quiz={quiz}
              closeModal={() => setProgress(undefined)}
            />
          ) : (
            <ModalWrapper>
              <ModalHeader>
                <ProgressBar
                  title={"Question"}
                  total={progress.quizStats.totalQuestions}
                  current={progress.quizStats.currentQuestion}
                />
              </ModalHeader>
              <ModalContent>
                <QuestionContent>
                  {progress.currentQuestion.content}
                </QuestionContent>

                <ul>
                  {progress.currentQuestion.answers.map((item) => (
                    <li key={item.id}>
                      <label key={item.content}>
                        <StyledCheckbox
                          onChange={() => handleOnClick(item.id)}
                          checked={selectedAnswersIds.includes(item.id)}
                          className="checkbox"
                        />

                        <MarkdownParser markdown={item.content} />
                      </label>
                    </li>
                  ))}
                </ul>

                <ButtonMain
                  disabled={selectedAnswersIds.length === 0}
                  onClick={() => answerQuestionMutation.mutate()}
                >
                  Next Question
                </ButtonMain>
                <LinearDeterminate
                  timeLimit={progress.currentQuestion.timeLimit}
                  id={progress.currentQuestion.id}
                />
              </ModalContent>
            </ModalWrapper>
          )}
        </ModalComponent>
      )}
    </div>
  );
};
