import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";

import { colors } from "../../shared/colors";
import {
  Quiz,
  QuizAttempt,
  QuizSummary as QuizSummaryT,
} from "../../../shared/api/interfaces";
import { getQuizSummary } from "../../../shared/api/requests";
import { ButtonMain } from "../components/ButtonMain";
import { Loader } from "../../shared/components/Loader";

const ModalWrapper = styled.div`
  overflow: hidden;
  background-color: ${colors.backgroundComponent};
  border-radius: 12px;
  box-shadow: 0px 1px 0 0 ${colors.shadowColor};
  width: 80vw;
`;

const Emoji = styled.p`
  font-size: 2.8125rem;
  margin-bottom: 0.5rem;
`;

const SuccessContent = styled.p`
  font-size: 2rem;
  font-weight: 600;
  line-height: 3rem;
  color: ${colors.fontActive};
  margin: 0 0 1.5rem;

  .underline {
    border-bottom: 1px solid ${colors.fontActive};
  }
`;

const Buttons = styled.div`
  margin-top: 1.5rem;
  display: flex;

  button {
    width: auto;
    margin-top: 1.5rem;
    margin-right: 1rem;
  }
`;

const FailContent = styled(SuccessContent)`
  color: ${colors.fontBase};

  .underline {
    border-bottom: 1px solid ${colors.fontBase};
  }
`;

const SummaryWrapper = styled.div`
  padding: 4rem 5rem;
`;

export const QuizSummary = ({
  quiz,
  progress,
  closeModal,
}: {
  quiz: Quiz;
  progress: QuizAttempt;
  closeModal: () => void;
}): JSX.Element => {
  const [summary, setSummary] = useState<QuizSummaryT>();
  // const queryClient = useQueryClient();
  // const taskSlug = useActiveTaskSlug();
  // const activeProject = useActiveProject();

  const getQuizSummaryMutation = useMutation(
    () => getQuizSummary(quiz.id, progress.id),
    {
      onSuccess: (summary) => {
        // queryClient.invalidateQueries([
        //   "sprints",
        //   { projectId: activeProject.id },
        // ]);
        setSummary(summary);
      },
    }
  );

  useEffect(() => {
    getQuizSummaryMutation.mutate();
  }, []);

  return (
    <ModalWrapper>
      <SummaryWrapper>
        {getQuizSummaryMutation.isLoading && <Loader />}
        {summary && (
          <>
            {summary.isPassed ? (
              <>
                <Emoji>🎉</Emoji>
                <SuccessContent>
                  Congratulations!
                  <br />
                  You passed the quiz by answering <br />
                  <span className="underline">
                    {summary.score} out of {summary.maxScore}
                  </span>{" "}
                  question correctly.
                </SuccessContent>
                <Buttons>
                  <ButtonMain onClick={closeModal}>Continue</ButtonMain>
                </Buttons>
              </>
            ) : (
              <>
                <Emoji>😔</Emoji>
                <FailContent>
                  Unfortunately you didn&apos;t make it. <br />
                  You answered correctly only{" "}
                  <span className="underline">
                    {summary.score} out of {summary.maxScore}
                  </span>{" "}
                  questions. <br />
                  You need{" "}
                  <span className="underline">
                    {summary.minCorrectAnswersToPass - summary.score} more
                  </span>{" "}
                  correct answers to pass.
                </FailContent>

                <Buttons>
                  <ButtonMain onClick={closeModal}>Try again</ButtonMain>
                </Buttons>
              </>
            )}
          </>
        )}
      </SummaryWrapper>
    </ModalWrapper>
  );
};
