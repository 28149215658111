export const colors = Object.freeze({
  backgroundBase: "#f4f5f7",
  backgroundComponent: "#ffffff",
  fontBase: "#242325",
  fontSecondary: "#6b778c",
  fontTertiary: "#363c46",
  fontActive: "#0165ff",
  fontActiveDarker: "#005ce6",
  activeHover: "rgba(1, 103, 255, 0.2)",
  fontLight: "#ffffff",
  fontLightDarker: "#eaf2fe",
  green: "#47bf7c",
  greenDarker: "#3b9b66",
  greenLight: "#edf9f2",
  disabled: "#c3c4c6",
  disabledText: "#AEB5C1",
  disabledButton: "#929394",
  border: "#c3c4c6",
  borderLight: "#f4f5f7",
  shadowColor: "#dfe1e6",
  error: "#EF6277",
  errorBackground: "#FDEFF1",
  modalBackground: "rgba(1, 55, 146, 0.9)",
});
