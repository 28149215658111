import React, { useMemo } from "react";
import { QueryClientProvider } from "react-query";
import StepWizard from "react-step-wizard";

import { Project, Membership } from "shared/api/interfaces";
import { queryClient } from "shared/api/queryClient";

import ConnectGithubAccount from "./steps/ConnectGithubAccount";
import CloneRepository from "./steps/CloneRepository";
import JoinDiscord from "./steps/JoinDiscord";
import WelcomeVideo from "./steps/WelcomeVideo";

const Onboarding = ({
  membership,
  authToken,
  project,
  dashboardPath,
}: {
  membership: Membership;
  authToken: string;
  project: Project;
  dashboardPath: string;
}): JSX.Element => {
  const initialStepForProjects = useMemo(() => {
    if (membership.githubRepositoryId) {
      return 4;
    }

    if (membership.isConnectedWithGithub) {
      return 2;
    }

    return 1;
  }, [membership]);

  return (
    <div className="onboarding-wrapper">
      <QueryClientProvider client={queryClient}>
        {project.variant === "course" ? (
          <StepWizard initialStep={1} isLazyMount>
            <JoinDiscord />
            <WelcomeVideo
              project={project}
              membership={membership}
              dashboardPath={dashboardPath}
            />
          </StepWizard>
        ) : (
          <StepWizard initialStep={initialStepForProjects} isLazyMount>
            <ConnectGithubAccount
              membership={membership}
              authToken={authToken}
            />
            <JoinDiscord />
            <CloneRepository
              projectId={membership.projectId}
              membership={membership}
            />
            <WelcomeVideo
              project={project}
              membership={membership}
              dashboardPath={dashboardPath}
            />
          </StepWizard>
        )}
      </QueryClientProvider>
    </div>
  );
};

export default Onboarding;
