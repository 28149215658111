import React from "react";
import CSS from "csstype";

interface IconProps {
  fill?: string;
  className?: string;
  style?: CSS.Properties;
  width?: string;
  height?: string;
}

export const CheckmarkIcon: React.FC<IconProps> = ({
  fill,
  className,
  style,
}) => {
  const fillColor = fill ? fill : "#6B778C";

  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fillColor}
        d="M8.47 0L3.75699 4.83L1.54999 2.737L0 4.289L3.758 7.93401L10.022 1.55099L8.47 0Z"
      />
    </svg>
  );
};

export const DiscordLogo: React.FC<IconProps> = ({ fill }) => {
  const fillColor = fill ? fill : "#5865f2";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 127.14 96.36"
      width="34"
      height="34"
    >
      <path
        fill={fillColor}
        d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"
      />
    </svg>
  );
};

export const LinkIcon: React.FC<IconProps> = ({ fill, className, style }) => {
  const fillColor = fill ? fill : "#EAF2FE";

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fillColor}
        d="M3.09383 4.35911C3.31335 4.13962 3.55687 3.95863 3.81588 3.81563C5.25948 3.02016 7.11061 3.44314 8.03867 4.8501L6.91559 5.97256C6.59357 5.23809 5.79402 4.8201 4.99846 4.99809C4.69894 5.06509 4.41442 5.21459 4.18191 5.44708L2.02976 7.60001C1.37622 8.25349 1.37622 9.31646 2.02976 9.96994C2.68331 10.6234 3.74638 10.6234 4.39992 9.96994L5.06347 9.30646C5.66701 9.54595 6.31405 9.64145 6.9531 9.59395L5.4885 11.0584C4.23291 12.3139 2.19727 12.3139 0.941689 11.0584C-0.313896 9.80294 -0.313896 7.76751 0.941689 6.51205L3.09383 4.35911ZM6.51207 0.941221L5.04747 2.40567C5.68601 2.35768 6.33355 2.45367 6.93709 2.69267L7.60014 2.02969C8.25368 1.37621 9.31676 1.37621 9.9703 2.02969C10.6238 2.68317 10.6238 3.74613 9.9703 4.39961L7.81765 6.55205C7.16211 7.20752 6.09754 7.20202 5.44749 6.55205C5.29598 6.40055 5.16547 6.21206 5.08397 6.02656L3.96089 7.14903C4.0789 7.32802 4.20141 7.48252 4.35892 7.64001C4.76495 8.046 5.28198 8.34849 5.87702 8.49199C6.64808 8.67748 7.47413 8.57498 8.18368 8.18349C8.4427 8.0405 8.68621 7.8595 8.90573 7.64001L11.0579 5.48758C12.314 4.23212 12.314 2.19668 11.0584 0.941221C9.80279 -0.31374 7.76765 -0.31374 6.51207 0.941221Z"
      />
    </svg>
  );
};

export const SigilLogo: React.FC<IconProps> = ({ fill, className, style }) => {
  const fillColor = fill ? fill : "#15181C";

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M26.88 0V25.6085C26.88 26.3128 26.304 26.889 25.6 26.889H15.36C14.6453 26.889 14.08 26.3128 14.08 25.6085V10.2434C14.08 9.53918 13.504 8.96299 12.8 8.96299H10.24C9.536 8.96299 8.96 9.53918 8.96 10.2434V25.5979C8.96 26.3021 8.384 26.8783 7.68 26.8783H0C0 29.7059 2.29333 32 5.12 32H26.8693C29.696 32 31.9893 29.7059 32 26.889V5.12171C32 2.2941 29.7067 0 26.88 0Z"
        fill={fillColor}
      />
      <path
        d="M5.12 21.7673V6.40213C5.12 5.6979 5.696 5.12171 6.4 5.12171H16.6293C17.3333 5.12171 17.9093 5.6979 17.9093 6.40213V21.7673C17.9093 22.4715 18.4853 23.0477 19.1893 23.0477H21.7493C22.464 23.0477 23.0293 22.4715 23.0293 21.7673V0H5.12C2.29333 0 0 2.2941 0 5.12171V23.0477H3.84C4.544 23.0477 5.12 22.4715 5.12 21.7673Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const CopyIcon: React.FC<IconProps> = ({ fill, className, style }) => {
  const fillColor = fill ? fill : "#EAF2FE";

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fillColor}
        d="M2 5.5H3V8.5H2V5.5ZM4.5 2H2V4.5H3V3H4.5V2ZM12 5.5H11V8.5H12V5.5ZM8.5 3V2H5.5V3H8.5ZM9.5 3H11V4.5H12V2H9.5V3ZM8.5 12V11H5.5V12H8.5ZM4.5 11H3V9.5H2V12H4.5V11ZM11 9.5V11H9.5V12H12V9.5H11ZM10.5 0H0V10.5H1V1H10.5V0Z"
      />
    </svg>
  );
};

export const ForkIcon: React.FC<IconProps> = ({ fill, className, style }) => {
  const fillColor = fill ? fill : "#000";

  return (
    <svg
      width="32"
      height="44"
      viewBox="0 0 32 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fillColor}
        d="M32 5.5C32 2.46217 29.6124 0 26.6667 0C23.7209 0 21.3333 2.46217 21.3333 5.5C21.3333 7.9255 22.864 9.9605 24.9778 10.692C25.2764 18.5662 21.3849 19.426 16.0924 20.5755C13.0542 21.2355 9.61778 21.9963 7.11111 24.398V10.6627C9.17867 9.9055 10.6667 7.8925 10.6667 5.5C10.6667 2.46217 8.27911 0 5.33333 0C2.38756 0 0 2.46217 0 5.5C0 7.8925 1.488 9.9055 3.55556 10.6627V33.3355C1.488 34.0945 0 36.1075 0 38.5C0 41.5378 2.38756 44 5.33333 44C8.27911 44 10.6667 41.5378 10.6667 38.5C10.6667 36.1258 9.20178 34.122 7.15911 33.352C7.63911 26.1617 11.728 25.2707 16.8249 24.1633C22.2258 22.99 28.8676 21.5197 28.5209 10.6388C30.5493 9.85967 32 7.86317 32 5.5ZM2.13333 5.5C2.13333 3.6795 3.568 2.2 5.33333 2.2C7.09867 2.2 8.53333 3.6795 8.53333 5.5C8.53333 7.3205 7.09867 8.8 5.33333 8.8C3.568 8.8 2.13333 7.3205 2.13333 5.5ZM8.53333 38.5C8.53333 40.3205 7.09867 41.8 5.33333 41.8C3.568 41.8 2.13333 40.3205 2.13333 38.5C2.13333 36.6795 3.568 35.2 5.33333 35.2C7.09867 35.2 8.53333 36.6795 8.53333 38.5ZM26.6667 8.8C24.9013 8.8 23.4667 7.3205 23.4667 5.5C23.4667 3.6795 24.9013 2.2 26.6667 2.2C28.432 2.2 29.8667 3.6795 29.8667 5.5C29.8667 7.3205 28.432 8.8 26.6667 8.8Z"
      />
    </svg>
  );
};

export const GithubLogo: React.FC<IconProps> = ({ fill, className, style }) => {
  const fillColor = fill ? fill : "#1F1A17";

  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fillColor}
        d="M17.29 0.0600052C13.4189 0.0560707 9.6729 1.43083 6.72333 3.93793C3.77376 6.44504 1.81341 9.9206 1.19359 13.7418C0.573781 17.5629 1.33502 21.4799 3.34089 24.7908C5.34675 28.1017 8.46613 30.5901 12.14 31.81C12.95 31.96 13.25 31.45 13.25 31.02C13.25 30.59 13.25 29.61 13.25 28.25C8.72 29.25 7.76 26.07 7.76 26.07C7.45815 25.0844 6.81688 24.2377 5.95 23.68C4.48 22.68 6.07 22.68 6.07 22.68C6.58529 22.7528 7.07727 22.9419 7.50867 23.2329C7.94007 23.524 8.2996 23.9094 8.56 24.36C8.78126 24.7612 9.07956 25.1148 9.43779 25.4004C9.79603 25.686 10.2071 25.8981 10.6475 26.0245C11.0879 26.1508 11.5489 26.189 12.0041 26.1367C12.4593 26.0845 12.8997 25.9429 13.3 25.72C13.3628 24.8974 13.7174 24.1242 14.3 23.54C10.68 23.13 6.87999 21.73 6.87999 15.49C6.85514 13.873 7.45307 12.3083 8.54999 11.12C8.06046 9.71418 8.11758 8.17566 8.71 6.81001C8.71 6.81001 10.08 6.37 13.19 8.48C15.8606 7.74676 18.6794 7.74676 21.35 8.48C24.46 6.37 25.82 6.81001 25.82 6.81001C26.4186 8.17372 26.4793 9.71337 25.99 11.12C27.0869 12.3083 27.6849 13.873 27.66 15.49C27.66 21.75 23.85 23.12 20.22 23.49C20.6092 23.8813 20.9098 24.3517 21.1013 24.8693C21.2928 25.3869 21.3708 25.9396 21.33 26.49C21.33 28.67 21.33 30.43 21.33 30.96C21.33 31.49 21.62 31.9 22.45 31.75C26.1123 30.5186 29.2181 28.0263 31.2134 24.7176C33.2088 21.4089 33.9639 17.499 33.3441 13.6852C32.7243 9.87145 30.7698 6.40187 27.8294 3.89532C24.889 1.38878 21.1538 0.00825826 17.29 0V0.0600052Z"
      />
    </svg>
  );
};

export const ArrowRight: React.FC<IconProps> = ({ className, style }) => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M5.06705 0.0961914L4.11505 1.12418L6.17905 3.36816H0.717041V4.81918H6.17905L4.11505 7.06317L5.06705 8.09119L8.73206 4.09119L5.06705 0.0961914Z" />
    </svg>
  );
};
