import React from "react";
import { useMutation } from "react-query";
import type { StepWizardChildProps } from "react-step-wizard";
import cn from "classnames";

import Footer from "../components/Footer";
import { ensure } from "../../shared/ensure";
import { MarkdownParser } from "../../shared/components/MarkdownParser";
import { completeOnboarding } from "../../../shared/api/requests";
import { Membership, Project } from "../../../shared/api/interfaces";

interface Props extends Partial<StepWizardChildProps> {
  project: Project;
  membership: Membership;
  dashboardPath: string;
}

const WelcomeVideo = ({
  project,
  membership,
  dashboardPath,
  ...stepWizardChildProps
}: Props): JSX.Element => {
  // Workaround for react-step-wizard having poor type definitions
  const totalSteps = ensure(stepWizardChildProps.totalSteps);
  const currentStep = ensure(stepWizardChildProps.currentStep);

  const { mutate, isLoading, isSuccess } = useMutation(
    (membershipId: string) => completeOnboarding(membershipId),
    {
      onSuccess: () => window.location.replace(dashboardPath),
    }
  );

  return (
    <div
      className={cn("step-container", {
        "no-player": !project.onboardingVideoUrl,
      })}
    >
      {project.onboardingVideoUrl && (
        <div className="player-wrapper">
          <div style={{ position: "relative", paddingTop: "56.25%" }}>
            <iframe
              src={`https://iframe.mediadelivery.net/embed/${project.onboardingVideoUrl}?autoplay=false&loop=false&muted=false&preload=false&responsive=true`}
              loading="lazy"
              style={{
                border: 0,
                position: "absolute",
                top: 0,
                height: "100%",
                width: "100%",
              }}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowFullScreen={true}
            />
          </div>
        </div>
      )}

      {!project.onboardingVideoUrl && (
        <div className="step-container__body">
          <MarkdownParser markdown={project.description} />
        </div>
      )}

      <Footer
        totalSteps={totalSteps}
        currentStep={currentStep}
        nextStep={() => mutate(membership.id)}
        nextStepButtonDisabled={false}
        nextStepEnabled={true}
      />
    </div>
  );
};

export default WelcomeVideo;
