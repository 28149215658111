import React from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";

import { colors } from "../../shared/colors";

export const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalWrapper: {
      position: "relative",
    },
    modalContentWrapper: {
      position: "relative",
      backgroundColor: colors.backgroundComponent,
      borderRadius: 12,
      boxShadow: `0px 1px 1px ${colors.shadowColor}`,
      minWidth: "340px",
      maxHeight: "80vh",
      overflow: "auto",
      "&:focus": {
        outline: "none",
      },
    },
    modalCloseButton: {
      position: "absolute",
      top: "0.6rem",
      right: "0.6rem",
      zIndex: 999,
      background: "none",
      border: "none",
    },
  })
);

export const StyledBackdrop = withStyles(() =>
  createStyles({
    root: {
      background: colors.modalBackground,
    },
  })
)(Backdrop);

interface ModalComponentProps {
  open: boolean;
  setOpen?: (value: boolean) => void;
  setClose?: () => void;
  withoutContentWrapper?: boolean;
  closeIconVisible?: boolean;
}

export const ModalComponent: React.FC<ModalComponentProps> = ({
  children,
  open,
  setOpen,
  setClose = () => setOpen && setOpen(false),
  withoutContentWrapper,
  closeIconVisible = false,
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={setClose}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        transitionDuration: 500,
      }}
    >
      <Fade in={open}>
        <div
          className={
            withoutContentWrapper
              ? classes.modalWrapper
              : classes.modalContentWrapper
          }
        >
          {closeIconVisible && (
            <button
              className={classes.modalCloseButton}
              onClick={setClose}
              data-test-id="close-modal-icon"
            >
              <CloseIcon />
            </button>
          )}

          {children}
        </div>
      </Fade>
    </Modal>
  );
};
