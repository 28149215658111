import React from "react";
import type { StepWizardChildProps } from "react-step-wizard";

import { CheckmarkIcon, DiscordLogo, LinkIcon, SigilLogo } from "../icons";
import Footer from "../components/Footer";
import { ensure } from "../../shared/ensure";

const JoinDiscord = ({
  ...stepWizardChildProps
}: Partial<StepWizardChildProps>): JSX.Element => {
  // Workaround for react-step-wizard having poor type definitions
  const totalSteps = ensure(stepWizardChildProps.totalSteps);
  const currentStep = ensure(stepWizardChildProps.currentStep);
  const nextStep = ensure(stepWizardChildProps.nextStep);

  const [nextStepDisabled, setNextStepDisabled] = React.useState(true);

  return (
    <div className="step-container">
      <div className="step-container__icon">
        <DiscordLogo />
        <LinkIcon fill="#000" />
        <SigilLogo />
      </div>

      <div className="step-container__body join-discord">
        <h2>Join our vibrant Discord community!</h2>

        <p>
          It&apos;s a great place to meet fellow learners, share ideas, and get
          help with your tasks.
        </p>
        <p>We can&apos;t wait to welcome you!</p>

        <div>
          {nextStepDisabled ? (
            <a
              className="action-button discord-logo"
              href="https://discord.gg/mMDycgU2qh"
              target="_blank"
              rel="noreferrer"
              onClick={() => setNextStepDisabled(false)}
            >
              <DiscordLogo fill="#fff" />
              Join Discord
            </a>
          ) : (
            <span className="action-button completed">
              <CheckmarkIcon />
              DONE!
            </span>
          )}
        </div>
      </div>

      <Footer
        totalSteps={totalSteps}
        currentStep={currentStep}
        nextStep={nextStep}
        nextStepButtonDisabled={false}
      />
    </div>
  );
};

export default JoinDiscord;
