import React from "react";
import CloseIcon from "@material-ui/icons/Close";

interface TaskIntroProps {
  introVideoUrl: string;
  onClose: () => void;
}

export const TaskIntro: React.FC<TaskIntroProps> = ({
  introVideoUrl,
  onClose,
}) => {
  return (
    <div className="playerWrapper">
      {/* <ReactPlayer
        url={introVideoUrl}
        className="reactPlayer"
        controls
        width="100%"
        height="100%"
        fallback={<Loader />}
      /> */}

      <button className="closeButton" onClick={onClose}>
        <CloseIcon style={{ fill: "#fff" }} fontSize="large" />
      </button>
    </div>
  );
};
