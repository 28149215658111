import React from "react";
import styled from "styled-components";

import { colors } from "../shared/colors";

interface ProgressBarProps {
  total: number;
  current: number;
  title?: string;
}
interface StepStyledProps {
  active: boolean;
}

const Title = styled.div`
  margin-right: 1rem;
  font-size: 0.75rem;
`;

const Step = styled.div<StepStyledProps>`
  background-color: ${(props) => (props.active ? colors.fontActive : "grey")};
  width: 1.6rem;
  height: 2px;
  margin: 0 1px;
  border-radius: 2px;
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressBar: React.FC<ProgressBarProps> = ({
  total,
  current,
  title,
}) => {
  const generateSteps = () => {
    const steps = [];
    for (let i = 0; i < total; i++) steps.push(i + 1);
    return steps;
  };

  const steps = generateSteps();
  return (
    <>
      {title && (
        <Title>
          {title} {current} of {total}
        </Title>
      )}
      <ProgressWrapper>
        {steps.map((item) => (
          <Step key={item} active={item <= current} />
        ))}
      </ProgressWrapper>
    </>
  );
};
